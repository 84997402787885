import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
  
export default () => {
  const data = useStaticQuery(graphql`
        query {
            allContentfulBlogPost(limit: 1000) {  
                edges {
                    node {
                        title
                    }
                }
            } 
        }
    `)
    return(
        <div>{ data.allContentfulBlogPost.edges.map(({ node }) => (
            <div key={node.id}>
              <div>{node.title}</div>
            </div>
          ))}
        </div>
    )
}